.dropdown-information-perfil-investigador .text-blue-propiedad-intelectual {
	font-weight: 600;
	font-size: 24px;
	color: #0495c1;
}
.dropdown-information-perfil-investigador p.current-text-propiedad-intelectual {
	font-size: 24px;
	color: #5f5e5e;
	margin-bottom: 0.5%;
	max-width: 1600px;
	line-height: 1.4;
}

/* .link-mas-proyectos {
	background-color: var(--primary);
	cursor: pointer;
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	width: fit-content;
	text-decoration: none;
	-webkit-border-radius: 70px;
	-moz-border-radius: 70px;
	border-radius: 70px;
	padding: 0.2% 2%;
	margin-top: 1.5%;
	margin-bottom: 3%;
	border: 0px;
} */

.current-text-publicaciones-cientificas {
	line-height: 1.1;
}

.information-perfil-investigador__wrap {
	margin-top: 15px;
}

.text-blue-publicaciones-cientificas-investigador {
	color: #339ec9;
	font-weight: 600;
	font-size: var(--titleSize);
	margin-bottom: 1%;
	max-width: 1600px;
	margin: 0.15%;
	line-height: 1.1;
	margin-top: 12px !important;
	margin-bottom: 7px !important;
}

@media screen and (max-width: 900px) {
	.link-mas-publicaciones-cientificas {
		font-size: var(--text-base);
	}
}
