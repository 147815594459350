.container-result-search-technology-2 {
	/* Comentado temporamente hasta que se agregue el Contacto */
	/* display: grid; */
	grid-template-columns: 75% 25%;
	text-align: center;
	max-width: var(--custom-max-width);
	margin: auto;
    margin-top: 1%;
}
.container-result-search-technology-2 .column {
	padding: 1%;
	padding-top: 0%;
	margin: 1%;
	margin-top: 0% !important;
}

.column {
	display: block;
	align-items: baseline;
	padding: 0.5rem;
	gap: 1rem;
    margin-bottom: 0% !important;
}

.MuiList-root{
	overflow-x: auto;
    padding-bottom: 1% !important;
}

.MuiListItem-root{
	justify-content: center !important;
	min-width: 8% !important;
}
