
.image-sdg img.enabled-img {
    width: 100%;
}

.image-sdg img.disabled-img{
    width: 80%;
    opacity: 0.1;
    
}
